<template>
  <div class="spinner-container">
<div class="lds-roller">
  <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
</div>
</div>
 <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
    watch: {
    $route: {
      // eslint-disable-next-line no-unused-vars
      handler (to, from) {
        document.title = to.meta.title +  ' | '  + 'الإهداء الذكي ' 
      },
      immediate: true
    },
    },
    mounted() {
      this.$nextTick(() => {
            this.setBorderTopByHref('/items');
        });
    },
    methods: {
      setBorderTopByHref(href) {
    const navLink = document.querySelector(`a[href="${href}"]`);
    if (navLink) {
        console.log(navLink); // This will log the element to the console for debugging
        navLink.style.borderTop = '1px solid #5d6377'; // Apply the border-top directly to the link
    } else {
        console.error(`Link with href "${href}" not found.`);
    }
}
    }
  
  }
</script>
